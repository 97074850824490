import React, { useEffect, useRef, useState } from "react";
import * as faceapi from "face-api.js";
import axios from "axios";
import "./videorecorder.css";
import { useHistory } from "react-router-dom";
import Header from "../../Shared/Header";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import { notification, Spin } from "antd";
import { useScrollTrigger } from "@mui/material";

const VideoCaptureComponent = ({ formData }) => {
  const videoRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [countdown, setCountdown] = useState(3);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [recordingTimeLeft, setRecordingTimeLeft] = useState(30);
  const [faceModelsLoaded, setFaceModelsLoaded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPreparingDataLoading, setIsPreparingDataLoading] = useState(false);
  const [smartVitalsData, setSmartVitalsData] = useState(null);
  
  const s_token = secureStorage.getItem("token");
  const storedData = JSON.parse(localStorage.getItem("patientInfo"));
  const history = useHistory();

  useEffect(() => {
    initializeCamera();
    loadFaceDetectionModels();

    return () => {
      stopCamera();
    };
  }, []);

  const initializeCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
        console.log("Camera initialized successfully.");
        setIsCameraOn(true);
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

 


  // const stopCamera = () => {
  //   console.log("dxfcgvbh")
  //   if (videoRef.current && videoRef.current.srcObject) {
  //     const stream = videoRef.current.srcObject;
  //     const tracks = stream.getTracks();
  //     tracks.forEach((track) => {
  //       track.stop();
  //     });
  //     // const tracks = videoRef.current.srcObject.getTracks();
  //     // tracks.forEach((track) => track.stop());
  //     setIsCameraOn(false);
  //   }
  //   navigator.mediaDevices.enumerateDevices().then((devices) => {
  //     console.log("Media devices:", devices);
  //   });
  // };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks(); // Get all tracks (video/audio)
      tracks.forEach((track) => track.stop()); // Stop each track
      videoRef.current.srcObject = null; // Clear the video element's srcObject
      setIsCameraOn(false);
      console.log("Camera stopped successfully.");
    }
  };

  const loadFaceDetectionModels = async () => {
    try {
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      setFaceModelsLoaded(true);
    } catch (error) {
      console.error("Error loading face detection models:", error);
    }
  };

  const startRecording = async () => {
   
    if (!isCameraOn || !videoRef.current) return;
    console.warn("Camera is not on or video element is not available.");
    setIsCountdownActive(true);
    let timer = countdown;

    console.log("Starting countdown:", countdown);

    const countdownInterval = setInterval(() => {
      setCountdown(--timer);
      console.log("Countdown:", timer);
      if (timer <= 0) {
        clearInterval(countdownInterval);
        setIsCountdownActive(false);
        setCountdown(3);
        console.log("Countdown complete. Starting recording.");
        beginRecording();
      }
    }, 1000);
  };

  const beginRecording = () => {
    console.log("beginRecording function triggered.");
    const isMp4Supported = MediaRecorder.isTypeSupported("video/mp4;codecs=avc1");
    const mimeType = isMp4Supported ? "video/mp4" : "video/webm";
    console.log("Using MIME type:", mimeType);
    const stream = videoRef.current.srcObject;
    const recorder = new MediaRecorder(stream,{ mimeType });
    const chunks = [];
    let stoppedByTimer = false;

    recorder.ondataavailable = (event) => {
      console.log(event.data,"rftgg")
      if (event.data.size > 0) {
        chunks.push(event.data);
       
      }
    };


    recorder.start();
    setMediaRecorder(recorder);
    setIsRecording(true);
    monitorFaceDetection(recorder);

    // 30-second recording timer
    let timeLeft = 30;
    setRecordingTimeLeft(timeLeft);
    const totalStrokeDasharray = 1256.64; 
    const recordingTimer = setInterval(() => {
      timeLeft -= 1;
      setRecordingTimeLeft(timeLeft);

      

      const progress = (timeLeft / 30) * totalStrokeDasharray;
    const progressCircle = document.querySelector(".video-recorder-circle-progress");
    if (progressCircle) {
      progressCircle.style.strokeDashoffset = progress.toString();
    }
      if (timeLeft <= 0) {
        clearInterval(recordingTimer);
        if (recorder.state !== "inactive") {
          recorder.stop(); 
          console.log("stop3");
          stoppedByTimer = true;
          
        }
       
        setIsRecording(false);
       
      }
    }, 1000);

    recorder.onstop = async () => {
      clearInterval(recordingTimer)
      console.log("Recording stopped.");
      const blob = new Blob(chunks, { type: mimeType });
      console.log("Recording stopped. Blob created:", blob);
      console.log(stoppedByTimer,"flag")
     if(stoppedByTimer === true ){
      uploadVideo(blob);
      stopCamera();
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        console.log("Media devices:", devices);
      });
      console.log("apicalledornot")
     }
      
    };
  
  };

  const monitorFaceDetection = (recorder) => {
    const interval = setInterval(async () => {
      if (!videoRef.current) {
        console.error("videoRef.current is null or undefined.");
        clearInterval(interval);
        return;
      }
      const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions());

      if (!detections.length) {
        console.warn("No face detected. Stopping recording.");
        clearInterval(interval);
        recorder.stop();
        
        setIsRecording(false);
        notification.warning({
          message: "Face Not Detected",
          description: "No face detected. Please position your face properly and try recording again.",
          duration: 5, 
          placement: "top", 
        });
      }
      else if (detections.length > 1) {
        console.warn("Multiple faces detected. Stopping recording.");
        clearInterval(interval);
        recorder.stop();
        
        setIsRecording(false);
        notification.warning({
          message: "Multiple Faces Detected",
          description: "Only one face is allowed. Please ensure only one person is in the frame and try recording again.",
          duration: 5,
          placement: "top",
        });
      }
    }, 1000);

    recorder.onstop = () => clearInterval(interval);
  };


  const stopRecording = () => {
    if (mediaRecorder) {
        console.log("Manually stopping recording.");
        mediaRecorder.stop();
        setIsRecording(false);
    }
};


  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  useEffect(()=>{
    const storedAnswers = JSON.parse(localStorage.getItem("calculatedAnswers"));
    console.log(storedAnswers,storedAnswers[0],"storedAnswers");
  })

  const uploadVideo = async (blob) => {
    console.log(blob,"videiocomponent")
    if (!blob || isSubmitted || isPreparingDataLoading) {
      return;
    }
    setIsPreparingDataLoading(true);

    const storedAnswers = JSON.parse(localStorage.getItem("calculatedAnswers"));
    console.log(storedAnswers,"storedAnswers");

    const formDataPayload = new FormData();
    formDataPayload.append(
      "name",
      `${storedData?.data?.firstName} ${storedData?.data?.lastName}`
    );
    formDataPayload.append("age", calculateAge(storedData?.data?.dob));
    formDataPayload.append("gender", storedData?.data?.gender);
    formDataPayload.append("height", parseInt(formData?.height, 10));
    formDataPayload.append("height_unit", formData?.heightUnit);
    formDataPayload.append("weight", parseInt(formData?.weight, 10));
    formDataPayload.append("weight_unit", formData?.weightUnit);
    formDataPayload.append("smoke", formData?.smoke);
    formDataPayload.append("diabetes", formData?.diabetes);
    formDataPayload.append("q1_perceived_stress", storedAnswers[0]);
    formDataPayload.append("q2_fatigue_level", storedAnswers[1]);
    formDataPayload.append("q3_sleep_quality_index", storedAnswers[2]);
    formDataPayload.append("q4_cognitive_impairment_index", storedAnswers[3]);
    formDataPayload.append("q5_overwork_index", storedAnswers[4]);
    formDataPayload.append("q6_mental_effort_index", storedAnswers[5]);
    formDataPayload.append("q7_nervousness", storedAnswers[6]);
    formDataPayload.append("q8_stress_level", storedAnswers[7]);
    formDataPayload.append("video", blob, "recorded-video.mp4");

    try {
      const response = await axios.post(
        "https://contactlessvitals-stage.curebay.in/process-video/",
        formDataPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${s_token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsPreparingDataLoading(false);
        setSmartVitalsData(response.data.data);
        // setVideoBlob(null);
        // setVideoURL(null);
        history.push({
          pathname: "/smart-vital-report",
          state: { responseData: response.data.data },
        });
      } else {
        throw new Error("API response not OK");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsPreparingDataLoading(false);
      setIsSubmitted(false);
    }
  };



  return (

<>
<Header />
<div className="video-recorder">
{isPreparingDataLoading ? (
          <div className=" flex flex-col items-center justify-center min-h-screen">
            <Spin />
            <p className="text-lg font-semibold mt-4 text-primaryColor">Please wait, preparing your vitals report</p>
          </div>
        ) :
( 
<div className="flex flex-col items-center justify-center min-h-screen">
    <div
      className="video-recorder-container relative flex items-center justify-center  h-[420px] w-[420px]"
  
    >
      <svg className="video-recorder-circle absolute" width="420" height="420">
        <circle
          cx="210"
          cy="210"
          r="200"
          strokeWidth="10"
          fill="none"
          strokeDasharray="1256.64" 
    strokeDashoffset="1256.64"
    className="video-recorder-circle-progress"
        />
      </svg>

      <video
        ref={videoRef}
        autoPlay
        muted
        className="video-recorder-video"
      />
    </div>

    {!isCountdownActive && !isRecording && (
      <>
      <button
        onClick={startRecording}
        disabled={!isCameraOn || !faceModelsLoaded}
        className="bg-primaryColor text-White p-4 mt-6 rounded-md shadow-lg"
      >
        Start Recording
      </button>
      

      </>
    )}

  
{isCountdownActive && (
  <div className="countdown-animation-container">
    <div className="countdown-number">{countdown}</div>
  </div>
)}


    {isRecording && (
      <div className="mt-6 flex flex-col items-center">
        <p className="text-lg font-semibold text-primaryColor">Recording... Time left: {recordingTimeLeft}s</p>
        <button
          onClick={stopRecording}
          className="bg-danger text-White p-3 mt-4 rounded-md shadow-lg"
        >
          Stop Recording
        </button>
      </div>
    )}
  </div>
)
}
</div>

</>

  );
};

export default VideoCaptureComponent;
