import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStates } from "../../../Redux/Action/hospitalpageActions";
import Layouts from "../../Layouts";
import { DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import cureimg from "../../../Assets/Images/Icons/cureimg.png";
import { FaCamera } from "react-icons/fa";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getPatientDetails,
  editPatientDetails,
} from "./../../../Redux/Action/UserprofileActions";
import { sendOtp } from "./../../../Redux/Action/userActions";
import http from "../../../Redux/Services/http-common";
import { encodeBase64File } from "../../Helper/filebase64";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TermsAndConditions from "../../../Assets/privacyPolicy.pdf";
import PrivacyPolicy from "../../../Assets/Terms&conditions.pdf";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import { Logout } from "./../../../Redux/Action/userActions";
import Loader from "../../Shared/loader";
import { Document, Page } from 'react-pdf';

const getTheAge = (date) => {
  return moment().diff(date, "years", false);
};
function Myprofile(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData, isLoading, isSuccess } = patientinfo;
  const [showinput, setshowinput] = useState(false);
  const [profile, setProfile] = useState();
  const [oldProfile, setOldProfile] = useState();
  const [loader, setLoader] = useState(false);
  const [isShow, setShow] = useState(false);
  const [isChange, setChange] = useState(false);
  const [errMsg, setErr] = useState("");
  const [membershipData, setMembershipData] = useState([]);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("terms");
  const [isVisible, setIsVisible] = useState(false);
  const [numPagestcPDf, setNumPagestcPDf] = useState();
  const [numPagesprivacy, setNumPagesPrivacy] = useState();
  const [loginVersion, setloginVersion] = useState([""]);
  // localStorage.setItem('savedCode',profile.code );
  const { patientSession } = userData;
  const [consentInfo, setConsentInfo] = useState({
    privacyPdf: "",
    privacyVersion: "",
    tcPdf: "",
    tcVersion: "",
  });
  const [loading, setLoading] = useState(true);

  const [showConsent, setShowConsent] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  useEffect(() => {
    const updateVh = () => {
      setVh(window.innerHeight * 0.01);
    };

    // Initial update
    updateVh();

    // Update on resize
    window.addEventListener('resize', updateVh);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', updateVh);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let modalWidth;
  if (width <= 767) {
    modalWidth = '100%'; // Mobile devices
  } else if (width >= 768 && width <= 1023) {
    modalWidth = '500px'; // Tablets and small laptops
  } else {
    modalWidth = '600px'; // Desktops and large laptops
  }
  useEffect(() => {
    dispatch(getAllStates())
      .then((result) => {
        setStateData(result);
      })
      .catch((error) => {});
  }, []);
  // const patientConsentList = async () => {
  //   try {
  //     let access_token = secureStorage.getItem('token')
  //     const response = await fetch(
  //       `${process.env.REACT_APP_NEWBASEURL}PatientConsent/list/filter?patientId=${userData.code}&status=1`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Accept: 'application/json',
  //           'Content-Type': 'application/json',
  //           Authorization: 'Bearer ' + access_token,
  //         },
  //       }
  //     );

  //     const data = await response.json();
  //     setprivacyPolicyConsent(data?.data[0]?.privacyPolicyConsent);
  //     settermsAndConditionConsent(data?.data[0]?.termsAndConditionConsent);

  //     // Check if terms and privacy policies are either both consented to or not
  //     // if (data?.data?.length > 0) {
  //     //   const consentData = data.data[0];
  //     //   if (
  //     //     (consentData.privacyPolicyConsent === 1 &&
  //     //       consentData.termsAndConditionConsent === 1) ||
  //     //     (consentData.privacyPolicyConsent === 0 &&
  //     //       consentData.termsAndConditionConsent === 0)
  //     //   ) {
  //     //     setIsVisible(false);
  //     //   }
  //     // }
  //   } catch (e) {
  //     console.log("Error fetching data:", e);
  //   }
  // };

  const getVersion = async () => {

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT_B2C`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setloginVersion(data?.data);
      console.log(data?.data,"consent")
      let info = {
        privacyPdf: data?.data[0].consentFile,
        privacyVersion: data?.data[0].versionNumber,
        tcPdf: data?.data[1].consentFile,
        tcVersion: data?.data[1].versionNumber
  
      }
      setConsentInfo(info)
    } catch (e) {
      console.log("Error fetching data:", e);
    }
  };

  const handleClick = () => {
    setShowConsent(!showConsent);
    // patientConsentList();
  };

  const agree = async () => {
    // if(checkBoxPresent){
    //   // selectCheckbox(true);
    //   setIsVisible(false);
    //   return;
    // }
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 1,
      sourceChannel: "B2C",
      status: 1,
      termsAndConditionConsent: 1,
      type: "P",
      patientId: userData.code,
      privacyVersionNumber: consentInfo.privacyVersion,
      tcVersionNumber: consentInfo.tcVersion,
    };

    console.log("Payload:", payload);
    setLoading(true)
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );
    setIsModalOpen(false);

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      setLoading(false)

      if (showConsent) {
        handleClick()
      }
      setIsVisible(false);
    }
  };

  const disAgree = async () => {
    // if(checkBoxPresent){
    //   selectCheckbox(false);
    //   setIsVisible(false);
    //   return;
    // }
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 0,
      sourceChannel: "B2C",
      status: 1,
      termsAndConditionConsent: 0,
      type: "P",
      patientId: userData.code,
      privacyVersionNumber: consentInfo.privacyVersion,
      tcVersionNumber: consentInfo.tcVersion,
    };
    setLoading(true)

    console.log("Payload:", payload);
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      setLoading(false)

      if (showConsent) {
        handleClick();
      }
      setIsVisible(false);
      logout();
    }
  };

  const logout = (e) => {
    localStorage.clear();
    let dataObj = {
      sessionId: patientSession?.sessionid,
      userCode: patientSession?.code,
    };
    setLoading(true)

    dispatch(Logout(dataObj));
    setLoading(false)

    history.push('/');
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const showModal = () => {
    getVersion()
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/', {isLoginModalOpen : true});
    }
  }, []);

  useEffect(() => {
    setLoading(true)

    dispatch(getPatientDetails(userData?.code));
    setLoading(false)

  }, []);
  useEffect(() => {
    setProfile(patientinfoData);
    setOldProfile(patientinfoData);
  }, [patientinfoData]);
  
  useEffect(() => {
    setLoading(true)

    http
      .get(
        `${process.env.REACT_APP_BASEURL}patientMembership/membershipDetails?patientId=${userData.code}`
      )
      .then((res) => {
        setMembershipData(res.data.data);
        setLoading(false)

      })
      .catch((err) => {
        console.log(err, "errr");
        setLoading(false)

      });
  }, [userData.code, isSuccess]);
  const handleChange = (e) => {
    console.log(e.target, "sdviosdgouvsduosdvsdb")
    if (e.target.name === "mobile" || e.target.name === "pinCode") {
      let re = /^[0-9\b]+$/;
      if (re.test(e.target.value) || e.target.value === "") {
        setProfile({ ...profile, [e.target.name]: e.target.value });
      }
    } else {
      setProfile({ ...profile, [e.target.name]: e.target.value });
    }
  };

  const handleUniqueIdChange = (value) =>{
    setProfile({ ...profile, uniqueId: value.toString() });
  }

  const handleKeyPress = (e) => {
    const { name } = e.target;

    let alphaRegex = /^[A-Za-z\s]*$/;

    if (
      (name === "firstName" || name === "lastName") && 
      !alphaRegex.test(e.key) && 
      e.key !== "Backspace"
    ) {
      e.preventDefault();
    }
};


  const disableFutureDates = (current) => {
    return current && current > moment().endOf('day'); 
  };
  console.log(profile,"profile")
  const handleDateChange = (date, dateString) => {
    if(dateString){
      setProfile({
        ...profile,
        ["dob"]: moment(dateString, "DD/MM/YYYY").format("MM/DD/YYYY"),
      });
    }else{
      setProfile({
        ...profile,
        ["dob"]: null,
      });
    }

  };
  const changeHandler = async (file) => {
    if (
      !file?.name.includes(".png") &&
      !file?.name.includes(".jpg") &&
      !file?.name.includes(".jpeg") &&
      !file?.name.includes(".webp")
    ) {
      toast("This file is not supported, Please choose an image");
      return;
    } else {
      let b64File = await encodeBase64File(file);
      setProfile({ ...profile, ["photo"]: b64File });
    }
  };
  const savechangesprofile = (e) => {
    e.preventDefault();
    profile.modifiedBy = patientinfoData.code;
    if (profile.salutation == "Select Salutation") {
      toast("Please select salutation!");
      return;
    }
    if (profile.firstName.length < 1) {
      toast("Please enter your firstname!");
      return;
    }
    if (profile.lastName.length < 1) {
      toast("Please enter your lastname!");
      return;
    }
    if (profile?.uniqueId?.length != 4) {
      toast("Please enter valid Aadhar number!");
      return;
    }
    if (!profile?.gender?.length) {
      toast("Please Select Gender!");
      return;
    }

    if(!profile.dob){
      toast("Please select date of birth!");
      return;
    }

    if(!profile.bloodGroup){
      toast("Please select blood group!");
      return;
    }
    var re = /\S+@\S+\.\S+/;
    if (!re.test(profile.email)) {
      toast("Please enter valid Email Id");
      return;
    }
    if (!profile.mobile) {
      toast("Please Enter Mobile Number");
      return;
    }
    if (profile.mobile.length < 10) {
      toast("Mobile no should be exact 10 digit!");
      return;
    }
    if (profile?.pinCode?.length < 6) {
      toast("Please enter a valid pincode!");
      return;
    }
    if (!profile?.state?.length) {
      toast("Please select state!");
      return;
    }
    if (oldProfile.mobile !== profile.mobile) {
      checkMobileExist();
    } else {
      setLoading(true)

      dispatch(editPatientDetails(patientinfoData.id, profile))
        .then((res) => {
          console.log(res, "ssdoufodsufosdnsd");
          if (res == 1) {
            setLoading(false)

            dispatch(getPatientDetails(userData.code));
            toast("Your Profile is updated successfully");
          } else {
            toast("Something went wrong");
          }
        })
        .catch((err) => {
          toast(err?.response?.data?.message);
          setLoading(false)

        });
      setshowinput(false);
    }
  };
  const checkMobileExist = async () => {
    let dataObj = {
      mobileNo: profile.mobile,
      mobileCheck: 1,
    };
    setLoading(true)
    dispatch(sendOtp(dataObj))
      .then(async (result) => {
        if (result) {
          setshowinput(false);
          setChange(true);
          setLoading(false)
        } else {
          setErr("something went wrong, please try again");
          setLoader(false);
          setShow(true);
        }
      })
      .catch((error) => {
        toast("This mobile number is already taken by other Patient.");
        setLoading(false)
        setChange(false);
          setShow(true);
        if (error?.details?.length > 0) {
          setChange(false);
          setShow(true);
          setErr(error.details[0]);
          setLoading(false)
        }
      });
  };
  const initialValues = {
    code: patientinfoData.code,
    firstName:
      patientinfoData && patientinfoData.firstName
        ? patientinfoData.firstName
        : "",
    lastName: patientinfoData.lastName,
    age: getTheAge(
      patientinfoData.dob ? patientinfoData.dob : moment().format("MM/DD/YYYY")
    ),
    name: patientinfoData.firstName,
    dob: moment(patientinfoData.dob, "MM/DD/YYYY"),
    salutation: patientinfoData.salutation,
    drugAllergies: patientinfoData.drugAllergies,
    pLanguage: patientinfoData.pLanguage,
    gender: patientinfoData.gender,
    bloodGroup: patientinfoData.bloodGroup,
    maritalStatus: patientinfoData.maritalStatus,
    email: patientinfoData.email,
    photo: patientinfoData.photo,
    photoName: patientinfoData.photoName,
    contactNo: patientinfoData.contactNo,
    mobile: patientinfoData.mobile,
    mobileCount: patientinfoData.mobileCount,
    address1: patientinfoData.address1,
    address2: patientinfoData.address2,
    address3:patientinfoData.address3,
    city: patientinfoData.city,
    state: patientinfoData.state === "AP"?"Andhra Pradesh":
    patientinfoData.state === "AS"?"Assam":
    patientinfoData.state === "BR"?"Bihar":
    patientinfoData.state === "CT"?"Chhattisgarh":
    patientinfoData.state === "DL"?"Delhi":
    patientinfoData.state === "GA"?"Goa":
    patientinfoData.state === "GJ"?"Gujarat":
    patientinfoData.state === "HR"?"Haryana":
    patientinfoData.state === "HP"?"Himachal Pradesh":
    patientinfoData.state === "JK"?"Jammu and Kashmir":
    patientinfoData.state === "JH"?"Jharkhand":
    patientinfoData.state === "KA"?"Karnataka":
    patientinfoData.state === "KL"?"Kerala":
    patientinfoData.state === "MP"?"Madhya Pradesh":
    patientinfoData.state === "MH"?"Maharashtra":
    patientinfoData.state === "MN"?"Manipur":
    patientinfoData.state === "ML"?"Meghalaya":
    patientinfoData.state === "MZ"?"Mizoram":
    patientinfoData.state === "NL"?"Nagaland":
    patientinfoData.state === "OD"?"Odisha":
    patientinfoData.state === "PY"?"Puducherry":
    patientinfoData.state === "PB"?"Punjab":
    patientinfoData.state === "PJ"?"Rajasthan":
    patientinfoData.state === "TN"?"Tamil Nadu":
    patientinfoData.state === "TS"?"Telangana":
    patientinfoData.state === "TR"?"Tripura":
    patientinfoData.state === "UP"?"Uttar Pradesh":
    patientinfoData.state === "UK"?"Uttarakhand":
    patientinfoData.state === "WB"?"West Bengal":
    patientinfoData.state,
    country: patientinfoData.country,
    role: patientinfoData.role,
    pinCode: patientinfoData?.pinCode,
    referredBy: patientinfoData.referredBy,
    source: patientinfoData.source,
    patientStatus: 1,
    status: patientinfoData.status,
    access: patientinfoData.access,
    createdBy: patientinfoData.createdBy,
    modifiedBy: patientinfoData.code,
    uniqueId: patientinfoData?.uniqueId ? patientinfoData?.uniqueId : "",
  };
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [patientinfoData]);

  function onDocumentLoadSuccesstcPdf({ numPages }) {
    setNumPagestcPDf(numPages);
  }

  function onDocumentLoadSuccessprivacy({numPages}) {
    setNumPagesPrivacy(numPages);
  }
  
  return (
    <Layouts>
      <div>
      {loading &&<Loader/>}

        <ToastContainer />
        {profile && (
          <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
            <div className="md:w-[80%] w-[90%] mx-auto ">
              <div className="md:w-full bg-primaryColor rounded-md md:px-8 px-6 py-3 md:py-5">
                <div className="flex justify-between items-center">
                  <div className="font-medium md:text-2xl text-sm text-White">
                    My Profile
                  </div>
                  <button
                    onClick={() => setshowinput(true)}
                    className="bg-White w-[80px] md:w-[10rem] h-7 md:h-12 text-xs md:text-sm rounded-md"
                  >
                    Edit Details
                  </button>
                </div>
              </div>
              <div className="mt-5 w-full">
                <div className="bg-White rounded-xl w-full p-4 md:p-5 md:common-shadow  mb-5">
                  <div className="flex gap-5">
                    <div className="rounded-full text-primaryColor w-[80px] h-[80px] p-2 common-shadow border border-lightBlue bg-lightBlue">
                      <img
                        src={
                          profile.photo
                            ? `${"data:image;base64,"}` + profile.photo
                            : profile.photoName
                              ? profile.photoName
                              : cureimg
                        }
                        alt="image"
                        className="cursor-pointer w-full h-full"
                      />
                      {showinput ? (
                        <>
                          <label className="cursor-pointer text-xs bg-brand-secondary text-white font-normal rounded">
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg, .webp"
                              onChange={(e) => {
                                changeHandler(e.target.files[0]);
                              }}
                              className="hidden"
                            />
                            <FaCamera className="w-7 h-7 ml-14" />
                          </label>
                        </>
                      ) : null}
                    </div>
                    <div className="">
                      <h2 className="text-base md:text-xl text-[#66B889] font-semibold mt-4">
                        {profile.firstName + " " + profile.lastName}
                      </h2>
                      <div className="md:flex gap-5">
                        <h3 className="text-xs md:text-sm text-textGray font-light mt-2">
                          Patient ID :
                          <span className="font-medium">
                            {" "}
                            {profile.code ? profile.code : ""}
                          </span>
                        </h3>
                        {membershipData?.length ? (
                          <h3 className="text-xs md:text-sm text-textGray font-light md:mt-2">
                            Memberships:
                            
                              {membershipData?.map((res, index) => <span className="font-medium">{res.membership} {res.membership.length < index ? "," : "" }{" "}</span>)}
                            
                          </h3>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 border-lightGray border-[1px]" />
                  <div className="mt-5">
                    <h2 className="text-base md:text-lg text-primaryColor font-semibold mb-4">
                      Personal Details
                    </h2>
                    <Form
                      form={form}
                      layout="vertical"
                      autoComplete="off"
                      className="CommonForm grid grid-cols-1 md:grid-cols-3 gap-5"
                    >
                      <Form.Item
                        name="salutation"
                        className="mb-0"
                        label={
                          <span>
                            Salutation <span className="text-[red] ">*</span>
                          </span>
                        }
                      >
                        <Select
                          placeholder="Select Salutation"
                          value={profile.salutation}
                          defaultValue={profile.salutation}
                          className="text-[#616469]"
                          optionFilterProp="children"
                          options={[
                            { value: "", label: "Select Salutation" },
                            { value: "Mr.", label: "Mr." },
                            { value: "Ms.", label: "Ms." },
                            { value: "Mrs.", label: "Mrs." },
                            { value: "Dr.", label: "Dr." },
                          ]}
                          onChange={(e) =>
                            setProfile({ ...profile, ["salutation"]: e })
                          }
                          disabled={!showinput}
                        />
                      </Form.Item>

                      <Form.Item
                        name="firstName"
                        className="mb-0"
                        label={
                          <span>
                            First Name <span className="text-[red] ">*</span>
                          </span>
                        }
                      >
                        <Input
                          value={profile.firstName}
                          defaultValue={profile.firstName}
                          name="firstName"
                          onChange={handleChange}
                          onKeyPress={handleKeyPress}
                          disabled={!showinput}
                          placeholder="Please enter your first name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="lastName"
                        className="mb-0"
                        label={
                          <span>
                            Last Name <span className="text-[red] ">*</span>
                          </span>
                        }
                      >
                        <Input
                          value={profile.lastName}
                          defaultValue={profile.lastName}
                          onChange={handleChange}
                          onKeyPress={handleKeyPress}
                          name="lastName"
                          disabled={!showinput}
                          placeholder="Please enter your last name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="gender"
                        className="mb-0"
                        label={
                          <span>
                            Gender <span className="text-[red] ">*</span>
                          </span>
                        }
                      >
                        <Select
                          placeholder="Select Gender"
                          value={profile.gender}
                          defaultValue={profile.gender}
                          optionFilterProp="children"
                          options={[
                            { value: "", label: "Select Gender" },
                            { value: "M", label: "Male" },
                            { value: "F", label: "Female" },
                            { value: "O", label: "Others" },
                          ]}
                          onChange={(e) =>
                            setProfile({ ...profile, ["gender"]: e })
                          }
                          disabled={!showinput}
                        />
                      </Form.Item>

                      <Form.Item
                        name="mobile"
                        className="mb-0"
                        label={
                          <span>
                            Mobile Number<span className="text-[red] ">*</span>
                          </span>
                        }
                      >
                        <Input
                          value={profile.mobile}
                          defaultValue={profile.mobile}
                          name="mobile"
                          onChange={handleChange}
                          disabled={!showinput}
                          placeholder="Please enter your mobile no"
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        className="mb-0"
                        label={
                          <span>
                            Email Id<span className="text-[red] ">*</span>
                          </span>
                        }
                      >
                        <Input
                          value={profile.email}
                          defaultValue={profile.email}
                          name="email"
                          onChange={handleChange}
                          disabled={!showinput}
                          placeholder="Please enter your email"
                        />
                      </Form.Item>

                      <Form.Item
                        name="uniqueId"
                        className="mb-0"
                        label={
                          <span>
                            Aadhar Id (last 4 digit)
                            <span className="text-[red] ">*</span>
                          </span>
                        }
                      >
                        <InputNumber
                          value={profile.uniqueId}
                          defaultValue={profile.uniqueId}
                          name="uniqueId"
                          onChange={handleUniqueIdChange}
                          disabled={!showinput}
                          placeholder="Please enter your Aadhar number"
                          maxLength={4}
                          // onKeyPress={(event) => {
                          //   if (!/[0-9.]/.test(event.key)) {
                          //     event.preventDefault();
                          //   }
                          // }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="dob"
                        label= {
                          <span>
                            Date of Birth
                            <span className="text-[red] ">*</span>
                          </span>
                        }
                        className="mb-0"
                      >
                        <DatePicker
                          // value={
                          //   profile.dob
                          //     ? moment(profile.dob, "MM/DD/YYYY")
                          //     : null
                          // }
                          // defaultValue={moment(profile.dob, "DD/MM/YYYY")}
                          placeholder="Please select your date of birth"
                          format="DD/MM/YYYY"
                          disabled={!showinput}
                          // disabledDate={disableFutureDates}
                          // onChange={handleDateChange}
                          className="w-full h-[50px] border"
                        />
                      </Form.Item>
                      <Form.Item
                        name="bloodGroup"
                        label= {
                          <span>
                          Blood Group
                          <span className="text-[red] ">*</span>
                        </span>
                        }
                        className="mb-0"
                      >
                        <Select
                          placeholder="Select Blood Group"
                          value={profile.bloodGroup}
                          defaultValue={profile.bloodGroup}
                          optionFilterProp="children"
                          options={[
                            { value: "", label: "Select Blood Group" },
                            { value: "O-", label: "O-" },
                            { value: "O+", label: "O+" },
                            { value: "A-", label: "A-" },
                            { value: "A+", label: "A+" },
                            { value: "B-", label: "B-" },
                            { value: "B+", label: "B+" },
                            { value: "AB-", label: "AB-" },
                            { value: "AB+", label: "AB+" },
                          ]}
                          onChange={(e) =>
                            setProfile({ ...profile, ["bloodGroup"]: e })
                          }
                          disabled={!showinput}
                        />
                      </Form.Item>
                      <Form.Item name="age" label="Age" className="mb-0">
                        <Input
                          value={getTheAge(profile.dob)}
                          defaultValue={getTheAge(profile.dob)}
                          disabled={true}
                          placeholder="Please enter your age"
                        />
                      </Form.Item>
                    </Form>
                    <hr className="border-lightGray border-[1px] mt-8" />
                    <div className="mt-5">
                      <h2 className="text-base md:text-lg text-primaryColor font-semibold mb-4">
                        Contact Details
                      </h2>
                      <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        className="CommonForm grid grid-cols-1 md:grid-cols-3 gap-5"
                      >
                        <Form.Item
                          name="address1"
                          label="House No/Street Name"
                          className="mb-0"
                        >
                          <TextArea
                            value={profile.address1}
                            defaultValue={profile.address1}
                            name="address1"
                            onChange={handleChange}
                            disabled={!showinput}
                            placeholder="House No/Flat No"
                          />
                        </Form.Item>
                        <Form.Item
                          name="address2"
                          //label="Village/Area"
                          label={
                            <span>
                              Village/Area
                            </span>
                          }
                          className="mb-0"
                        >
                          <TextArea
                            value={profile.address2}
                            defaultValue={profile.address2}
                            name="address2"
                            onChange={handleChange}
                            disabled={!showinput}
                            placeholder="Village/Area"
                          />
                        </Form.Item>

                       
                        <Form.Item
                          name="address3"
                          // label="Block/city"
                          className="mb-0"
                          label={
                            <span>
                              Block/city
                            </span>
                          }
                        >
                          <TextArea
                            value={profile.address3}
                            defaultValue={profile.address3}
                            name="address3"
                            onChange={handleChange}
                            disabled={!showinput}
                            placeholder="Block/city"
                          />
                        </Form.Item>
                        <Form.Item
                          name="pinCode"
                          className="mb-0"
                          label={
                            <span>
                              Pin Code<span className="text-[red] ">*</span>
                            </span>
                          }
                        >
                          <Input
                            value={profile.pinCode}
                            defaultValue={profile.pinCode}
                            name="pinCode"
                            onChange={handleChange}
                            disabled={!showinput}
                            placeholder="Please enter your pincode"
                            maxLength={6}
                            onKeyPress={(event) => {
                              if (!/[0-9.]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>

                        <Form.Item name="state"   label={
                            <span>
                              State<span className="text-[red] ">*</span>
                            </span>
                          } className="mb-0">
                                                   <Select
                            showSearch
                            value={profile.state}
                            defaultValue={profile.state}
                            placeholder="Select your state"
                            optionFilterProp="children"
                            options={stateData.map((hc) => ({
                              value: hc.code,
                              value1: hc.description,
                              label: hc.description,
                              key: hc.code,
                            }))}
                            filterOption={(input, option) =>
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) =>
                              setProfile({ ...profile, ["state"]: e })
                            }
                            disabled={!showinput}
                          />
                        </Form.Item>
                        <Form.Item name="city"   label={
                            <span>
                              City
                            </span>
                          } className="mb-0">
                          <Input
                            value={profile.city}
                            defaultValue={profile.city}
                            name="city"
                            onChange={handleChange}
                            disabled={!showinput}
                            placeholder="Please enter your city"
                          />
                        </Form.Item>
                      </Form>
                      {showinput && (
                        <div className="md:flex justify-between items-center mt-8">
                          <h3 className="underline text-secondaryColor text-base cursor-pointer" onClick={showModal}>
                            Update your consent permissions.
                          </h3>
                          <div className="flex mt-5 md:mt-0 gap-5">
                            <button
                              onClick={() => setshowinput(false)}
                              className="w-full text-sm border border-primaryColor text-primaryColor h-10 px-5 rounded-md"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={savechangesprofile}
                              className="w-full text-sm bg-primaryColor text-White h-10 px-5 rounded-md"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        styles={{
          content: {
              height: `${vh * 98}px`,
              width: modalWidth,
              // overflow:"scroll"
          },
          footer:{
            alignContent: "center",
            paddingRight: 10,
            height: "10%"
          },
          body:{
            height: "88%" 
          }
        }}
        footer={[
          <button
            onClick={disAgree}
            className=" text-white  text-sm font-semibold px-2 py-2 rounded cursor-pointer lg:px-18 mr-4"
            style={{ backgroundColor: "red", color: "#fff" }}
          >
            Disagree
          </button>,
          <button
          onClick={agree}
          className=" text-white  text-sm font-semibold px-2 py-2 rounded cursor-pointer lg:px-18"
          style={{ backgroundColor: "#66B889", color: "#fff" }}
        >
          Agree
        </button>
      ]}
      >
        <div className="flex text-xl font-semibold gap-6 p-2 text-primaryColor w-full">
          <div
            onClick={() => handleTabClick("terms")}
            className={`text-xl p-1  cursor-pointer transition-shadow duration-1000 ${activeTab === "terms"
              ? "border-b-4 border-primaryColor rounded-t-lg"
              : "text-textGray"
              }`}
          >
            Terms and Conditions
          </div>
          <div
            onClick={() => handleTabClick("privacy")}
            className={`cursor-pointer p-1 transition-shadow duration-1000 ${activeTab === "privacy"
              ? "border-b-4 border-primaryColor rounded-t-lg"
              : "text-textGray"
              }`}
          >
            Privacy Policy
          </div>
        </div>
        <div className="consentBodyContainer">
          {activeTab === "terms" && (
            <div className="consentcont overflow-scroll">
              {/* <iframe
                          // height="95px"
                          // width="68px"
                          className="border rounded cursor-pointer h-96 w-full"
                          src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}`}
                        /> */}
                          <Document file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}`} onLoadSuccess={onDocumentLoadSuccesstcPdf}>
               {numPagestcPDf &&
          Array.from({ length: numPagestcPDf }, (_, index) => index + 1).map(
            (pageNumber) => <Page  width={600} pageNumber={pageNumber} renderTextLayer = {true} renderAnnotationLayer = {false}  />
          )}      
      </Document>
            </div>
          )}
          {activeTab === "privacy" && (
            <div className="consentcont overflow-scroll">
               {/* <iframe
                          // height="95px"
                          // width="68px"
                          className="border rounded cursor-pointer h-96 w-full"
                          src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}`}
                        /> */}
                        <Document file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}`} onLoadSuccess={onDocumentLoadSuccessprivacy} >
               {numPagesprivacy &&
          Array.from({ length: numPagesprivacy }, (_, index) => index + 1).map(
            (pageNumber) => <Page  width={600} pageNumber={pageNumber} renderTextLayer = {true} renderAnnotationLayer = {false} />
          )}      
      </Document>
            </div>
          )}
        </div>
      </Modal>
    </Layouts>
  );
}

export default Myprofile;
