import React, { useState } from 'react'
import Header from '../../Shared/Header'
import { Typography, Radio, Divider, Card, Row, Col, message, Button } from "antd";


const { Title } = Typography;

const questions = [
    {
      id: 1,
      question:
        "How often do you experience physical pain or discomfort without a clear cause (e.g., headaches, body pain, muscle aches)?",
      options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
    },
    {
      id: 2,
      question:
        "How often do you feel physically exhausted or drained, even after a normal day of work or activity?",
      options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
    },
    {
      id: 3,
      question:
        "How well do you sleep at night? (i.e., feel rested upon waking up)",
      options: [
        "Very well (restful sleep, wake up refreshed)",
        "Mostly well, but sometimes restless",
        "Average, often wake up tired",
        "Poor, wake up multiple times during the night",
        "Very poor, feel unrested throughout the day",
      ],
    },
    {
      id: 4,
      question:
        "In the past two weeks, how often have you experienced difficulty concentrating, making decisions, or remembering things?",
      options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
    },
    {
      id: 5,
      question: "Do you feel overwhelmed by daily tasks or responsibilities?",
      options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
    },
    {
      id: 6,
      question:
        "Have you noticed any changes in your appetite (e.g., eating more or less than usual)?",
      options: [
        "No change",
        "Slight decrease",
        "Slight increase",
        "Significant decrease",
        "Significant increase",
      ],
    },
    {
      id: 7,
      question:
        "How often do you find yourself feeling anxious, worried, or nervous without a clear reason?",
      options: ["Never", "Rarely", "Sometimes", "Often", "Very frequently"],
    },
    {
      id: 8,
      question:
        "Have you experienced any recent life changes or stressors that might be affecting your health (e.g., work pressure, family issues, loss)?",
      options: [
        "No",
        "Yes, but only minor stressors",
        "Yes, moderate stressors",
        "Yes, major stressors",
        "Yes, overwhelming or traumatic stressors",
      ],
    },
  ];

function QuizComponent({setFlagquizsubmit}) {

    const [answers, setAnswers] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // const handleChange = (questionId, value) => {
    //   setAnswers((prev) => ({ ...prev, [questionId]: value }));
    // };

    const handleChange = (questionId, selectedOption) => {
      setAnswers((prev) => ({
        ...prev,
        [questionId]: selectedOption, // Store the selected option directly
      }));
    };
    console.log(answers,"gtggygyg")

    // const handleNext = () => {
    //     const unanswered = questions.filter((q) => !answers[q.id]);
    
    //     if (unanswered.length > 0) {
          
    //       message.error(`Please answer all the questions before proceeding.`);
    //     } else {
         
    //       setIsSubmitting(true);
    //       setTimeout(() => {
    //         message.success("All questions answered. Proceeding...");
    //         setFlagquizsubmit(true);
    //         setIsSubmitting(false);
    //       }, 1000);
    //     }
    //   };

      const handleNext = () => {
        // Check for unanswered questions
        const unanswered = questions.filter((q) => !(q.id in answers));
        if (unanswered.length > 0) {
          message.error(`Please answer all the questions before proceeding.`);
          return;
        }
    
        // Calculate final values based on the selected options
        const calculatedAnswers = Object.entries(answers).map(([id, selectedOption]) => {
          const question = questions.find((q) => q.id === parseInt(id));
          const selectedOptionIndex = question.options.indexOf(selectedOption);
          return Number(((selectedOptionIndex + 1) * 0.2).toFixed(1));
        });

        localStorage.setItem("calculatedAnswers", JSON.stringify(calculatedAnswers));
    
        setIsSubmitting(true);
    
        setTimeout(() => {
          message.success("All questions answered. Proceeding...");
          console.log("Calculated Answers Array:", calculatedAnswers);
          setFlagquizsubmit(true);
          setIsSubmitting(false);
        }, 1000);
      };



  return (
    <>
      <Header />

      <div className='p-8'>
      <div className="bg-primaryColor text-white py-4 rounded-lg shadow-md w-full mt-24 px-4">
          <p className="font-semibold text-lg ml-3 text-White">
          Self-Care Check-In: 
            <span className="font-normal ml-2 text-base text-White">
            Please answer the following question
            </span>
          </p>
        </div>
         {/* Questions Section */}
         <Row
          gutter={[24, 24]}
          style={{
            marginTop: "20px",
          
          }}
        >
          {questions.map((item, index) => (
            <Col
              key={item.id}
              xs={24} // Full width on mobile
              sm={24} // Full width on small devices
              md={12} // Two columns on medium and larger devices
            >
              <Card
                bordered
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  padding: "16px",
                }}
              >
                <Title
                  level={5}
                  style={{
                    marginBottom: "16px",
                    color: "#333",
                    fontSize: "16px",
                  }}
                >
                  {`Q${index + 1}) ${item.question}`}
                </Title>
                <Radio.Group
                  onChange={(e) => handleChange(item.id, e.target.value)}
                  value={answers[item.id]}
                  style={{ display: "flex", flexDirection: "column", gap: "8px" }}
                >
                  {item.options.map((option, optIndex) => (
                    <Radio key={optIndex} value={option}>
                      {option}
                    </Radio>
                  ))}
                </Radio.Group>
              </Card>
            </Col>
          ))}
        </Row>

        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            type="primary"
            size="large"
            onClick={handleNext}
            loading={isSubmitting}
            className='px-10 py-6 font-bold text-lg'
          >
            Next
          </Button>
        </div>
    </div>
    </>
  )
}

export default QuizComponent