import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "./AppRoutes";
import { checkIsLoggedIn } from "../../Redux/Action/userActions";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Home from "../Pages/Home";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsConditions from "../Pages/TermsConditions";
import Blogs from "../Pages/Blogs";
import BlogDetails from "../Pages/BlogDetails";
import MediaNews from "../Pages/MediaNews";
import Awards from "../Pages/Awards";
import WhyCurebay from "../Pages/WhyCurebay";
import RefundPolicy from "../Pages/RefundPolicy";
import MedicineDeliveryPolicy from "../Pages/MedicineDeliveryPolicy";
import SwashtyaMitra from "../Pages/Partnership/SwashtyaMitra";
import Franchise from "../Pages/Partnership/Franchise";
import OurTeam from "../Pages/OurTeam";
import Concierge from "../Pages/Services/Concierge";
import BookanAppointment from "../Pages/Services/BookanAppointment";
import AlliedService from "../Pages/Partnership/AlliedService";
import HospitalService from "../Pages/Partnership/HospitalService";
import DiagnosticService from "../Pages/Partnership/DiagnosticService";
import DoctorsService from "../Pages/Partnership/DoctorsService";
import AboutUs from "../Pages/AboutUs";
import Medicine from "../Pages/Services/Medicines";
import Career from "../Pages/Partnership/Career";
import Surgery from "../Pages/Services/Surgery";
import FindDoctors from "../Pages/Services/FindDoctors";
import Lab from "../Pages/Services/Lab";
import BlogDetails2 from "../Pages/BlogDetails2";
import BlogDetails3 from "../Pages/BlogDetails3";
import BlogDetails4 from "../Pages/BlogDetails4";
import BlogDetails5 from "../Pages/BlogDetails5";
import BlogDetails6 from "../Pages/BlogDetails6";
import Membership from "../Pages/Services/Membership";
import Eprescription from "../Pages/Services/Eprescriptions";
import SurgeryDetails from "../Pages/Services/SurgeryDetails";
import DoctorBooking from "../Pages/Services/DoctorBooking";
import Speciality from "../Pages/Services/Speciality";
import DoctorPayment from "../Pages/Services/DoctorPayment";
import TotalCare from "../Pages/Services/TotalCare";
import DiagnosticDetails from "../Pages/Services/DiagnosticDetails";
import ProductInfo from "../Pages/Services/ProductInformation";
import MyPayment from "../Pages/PostLogin/MyPayments";
import MyWallet from "../Pages/PostLogin/MyWallet";
import MyRequests from "../Pages/PostLogin/MyRequest";
import Myprofile from "../Pages/PostLogin/MyProfile";
import Manageprofile from "../Pages/PostLogin/ManageProfile";
import AddFamilyMember from "../Shared/AddFamilyMember";
import CartPage from "../Pages/CartPage";
import MyVitals from "../Pages/PostLogin/HealthRecords/MyVitals";
import AddAddressBook from "../Pages/PostLogin/AddAddressBook";
import Feedback from "../Pages/PostLogin/FeedBack";
import MedicalHistory from "../Pages/PostLogin/HealthRecords/MedicalHistory";
import MyReports from "../Pages/PostLogin/HealthRecords/MyReports";
import MyPrescription from "../Pages/PostLogin/HealthRecords/MyPrescription";
import MyLabReports from "../Pages/PostLogin/HealthRecords/MyLabReports";
import MyAppointments from "../Pages/PostLogin/HealthRecords/MyAppointments";
import Savedprescription from "../Pages/Services/SavedPrescription";
import MembershipCard from "../Pages/PostLogin/Orders/MemberShip";
import LabOrders from "../Pages/PostLogin/Orders/MyLabOrders";
import MedicineOrders from "../Pages/PostLogin/Orders/MedicineOrder";
import HospitalEnquire from "../Pages/Services/HospitalEnquire";
import EnquireFormSubmit from "../Pages/Services/EnquireFormSubmit";
import MedicalSummary from "../Pages/PostLogin/MedicalSummary/MedicalSummary";
import VideoCall from "../Pages/PostLogin/VideoCall";
import AppoinmentConfirmation from "../Pages/Services/AppoinmentConfirmation";
import MedicineCart from "../Pages/cart/CartContainer";
import Checkout from "../Pages/cart/Checkout";
import CartReview from "../Pages/cart/CartReview";
import MedicineOrder from "../Pages/MedicineOrderPlaced";
import ChangePassword from "../Pages/PostLogin/ChangePassword";
import ForgotPasswordReset from "../Authentications/ForgotPasswordReset";
import CartOrderSucess from "../Pages/cart/cartOrderSucess";
import LabView from "../Pages/Services/LabView";
import AllSearch from "../Pages/AllSearch";
import CareSathi from "../Pages/CareSathi/CareSathi";
import PrescriptionLink from "../Pages/Services/prescriptionLink";
import Dentalcare from "../Pages/Services/Dentalcare";
import SmartVitalsDetails from "../Pages/SmartVitals/SmartVitalsDetails";
import LabInsight from "../Pages/LabInsight/Labinsight";
import SmartVitalsChecker from "../Pages/SmartVitals/SmartVitalsChecker";
import SmartVitalsReport from "../Pages/SmartVitals/SmartVitalsReport";
import CaresathiMainpage from "../Pages/CareSathi/CaresathiMainpage";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import SmartVitalsInstruct from "../Pages/SmartVitals/smartVitalsInstruct";
import PastOrders from "../Pages/Services/pastOrders";
import WhyCurebayNative from "../Pages/WhyCurebayNative";
import MembershipDocument from "../Pages/Services/membershipDoc";

const PageRouting = () => {

 
  if(!localStorage.getItem("refreshed")){
    localStorage.clear();
    localStorage.setItem("refreshed" , true)
    window.location.reload();
  }


  return (
    <Router>

      <Switch>
        <Route exact path={APP_ROUTES.HOME} component={Home} />
        <Route exact path={APP_ROUTES.OURTEAM} component={OurTeam} />
        <Route
          exact
          path={APP_ROUTES.PRIVACYPOLICY}
          component={PrivacyPolicy}
        />
        <Route
          exact
          path={APP_ROUTES.TERMSCONDITIONS}
          component={TermsConditions}
        />
        <Route exact path={APP_ROUTES.REFUNDPOLICY} component={RefundPolicy} />
        <Route
          exact
          path={APP_ROUTES.MEDICINEDELIVERYPOLICY}
          component={MedicineDeliveryPolicy}
        />
        <Route exact path={APP_ROUTES.BLOGS} component={Blogs} />
        <Route exact path={APP_ROUTES.BLOGSDETAILS} component={BlogDetails} />
        <Route exact path={APP_ROUTES.BLOGSDETAILS2} component={BlogDetails2} />
        <Route exact path={APP_ROUTES.BLOGSDETAILS3} component={BlogDetails3} />
        <Route exact path={APP_ROUTES.BLOGSDETAILS4} component={BlogDetails4} />
        <Route exact path={APP_ROUTES.BLOGSDETAILS5} component={BlogDetails5} />
        <Route exact path={APP_ROUTES.BLOGSDETAILS6} component={BlogDetails6} />
        <Route exact path={APP_ROUTES.HOSPITAL} component={HospitalEnquire} />
        <Route
          exact
          path={APP_ROUTES.HOSPITALENQUIRE}
          component={EnquireFormSubmit}
        />
       
        <Route exact path={APP_ROUTES.MEDIANEWS} component={MediaNews} />
        <Route exact path={APP_ROUTES.AWARDS} component={Awards} />
        <Route exact path={APP_ROUTES.WHYCUREBAY} component={WhyCurebay} />
        <Route exact path={APP_ROUTES.WHYCUREBAYNATIVE} component={WhyCurebayNative} />
        <Route
          exact
          path={APP_ROUTES.SWASHTYAMITRA}
          component={SwashtyaMitra}
        />
        <Route exact path={APP_ROUTES.FRANCHISE} component={Franchise} />
        <Route
          exact
          path={APP_ROUTES.HOSPITALSERVICE}
          component={HospitalService}
        />
        <Route
          exact
          path={APP_ROUTES.DIAGNOSTICSERVICE}
          component={DiagnosticService}
        />
        <Route
          exact
          path={APP_ROUTES.DOCTORSSERVICE}
          component={DoctorsService}
        />
        <Route
          exact
          path={APP_ROUTES.ALLIEDSERVICE}
          component={AlliedService}
        />
        <Route
          exact
          path={APP_ROUTES.PAST_ORDERS}
          component={PastOrders}
        />
        <Route exact path={APP_ROUTES.CAREER} component={Career} />
        <Route exact path={APP_ROUTES.CONCIERGE} component={Concierge} />
        <Route
          exact
          path={APP_ROUTES.BOOKANAPPOINTMENT}
          component={BookanAppointment}
        />
        <Route exact path={APP_ROUTES.MEDICINE} component={Medicine} />
        <Route exact path={APP_ROUTES.MEMBERSHIP} component={Membership} />
        <Route exact path={APP_ROUTES.TOTALCARE} component={TotalCare} />
        <Route exact path={APP_ROUTES.LAB} component={Lab} />
        <Route exact path={APP_ROUTES.SURGERY} component={Surgery} />
        <Route
          exact
          path={APP_ROUTES.SURGERYDETAILS}
          component={SurgeryDetails}
        />
        <Route exact path={APP_ROUTES.FINDDOCTORS} component={FindDoctors} />
        <Route exact path={APP_ROUTES.ABOUTUS} component={AboutUs} />
        <Route
          exact
          path={APP_ROUTES.EPRESCRIPTION}
          component={Eprescription}
        />
        <Route exact path={APP_ROUTES.SPECIALITY} component={Speciality} />
        <Route exact path={APP_ROUTES.DOCTOR} component={DoctorBooking} />
        <Route exact path={APP_ROUTES.PAYMENT} component={DoctorPayment} />
        <Route
          
          path={APP_ROUTES.DIAGNOSTICDETAILS}
          component={DiagnosticDetails}
        />
        <Route exact path={APP_ROUTES.MEDICININFO} component={ProductInfo} />
        <Route
          exact
          path={APP_ROUTES.MYAPPOINTMENTS}
          component={MyAppointments}
        />
        <Route exact path={APP_ROUTES.MYREPORTS} component={MyReports} />
        <Route exact path={APP_ROUTES.MYLABREPORTS} component={MyLabReports} />
        <Route
          exact
          path={APP_ROUTES.MYPRESCRIPTION}
          component={MyPrescription}
        />
        <Route exact path={APP_ROUTES.MYPAYMENT} component={MyPayment} />
        <Route exact path={APP_ROUTES.MYWALLET} component={MyWallet} />
        <Route
          exact
          path={APP_ROUTES.MYMEMBERSHIP}
          component={MembershipCard}
        />
        <Route exact path={APP_ROUTES.MYREQEUSTS} component={MyRequests} />
        <Route exact path={APP_ROUTES.MYPROFILE} component={Myprofile} />
        <Route
          exact
          path={APP_ROUTES.MANAGEPROFILE}
          component={Manageprofile}
        />
        <Route
          exact
          path={APP_ROUTES.ADDFAMILYMEMBER}
          component={AddFamilyMember}
        />
        <Route
          exact
          path={APP_ROUTES.MEDICALHISTORY}
          component={MedicalHistory}
        />
        {/* <Route exact path={APP_ROUTES.CARTPAGE} component={CartPage} /> */}
        <Route exact path={APP_ROUTES.MYVITALS} component={MyVitals} />
        <Route
          exact
          path={APP_ROUTES.ADDADDRESSBOOK}
          component={AddAddressBook}
        />
        <Route exact path={APP_ROUTES.LABORDER} component={LabOrders} />
        <Route exact path={APP_ROUTES.FEEDBACK} component={Feedback} />
        <Route
          exact
          path={APP_ROUTES.SAVEDPRESCRIPTIONS}
          component={Savedprescription}
        />
        <Route
          exact
          path={APP_ROUTES.SAVEDPRESCRIPTION}
          component={PrescriptionLink}
        />
        <Route
          exact
          path={APP_ROUTES.MEDICINEORDER}
          component={MedicineOrders}
        />
        <Route
          exact
          path={APP_ROUTES.MEDICALSUMMARY}
          component={MedicalSummary}
        />
        <Route
          exact
          path={APP_ROUTES.CHANGEPASSWORD}
          component={ChangePassword}
        />
        <Route
          exact
          path={APP_ROUTES.FORGETPASSWORDRESET}
          component={ForgotPasswordReset}
        ></Route>
        <Route exact path={APP_ROUTES.LABVIEW} component={LabView}></Route>
        <Route
          exact
          path={APP_ROUTES.MEDICINE_CART}
          component={MedicineCart}
        ></Route>
        <Route exact path={APP_ROUTES.SEARCHALL} component={AllSearch}></Route>
        <Route exact path={APP_ROUTES.SMARTVITALSDETAILS} component={SmartVitalsDetails}></Route>
        <Route exact path={APP_ROUTES.LABINSIGHT} component={LabInsight}></Route>
        <Route exact path={APP_ROUTES.CARESATHI} component={CaresathiMainpage}></Route>
        <Route
          exact
          path={APP_ROUTES.SMARTVITALS_REPORT}
          component={SmartVitalsReport}
        ></Route>
        <Route
          exact
          path={APP_ROUTES.DENTALCARE}
          component={Dentalcare}
        ></Route>
         <Route
          exact
          path={APP_ROUTES.VIDEO_CALL}
          component={VideoCall}
        ></Route>
        <PrivateRoute
          exact
          path={APP_ROUTES.CHECKOUT}
          component={Checkout}
        ></PrivateRoute>
        
         <PrivateRoute
          exact
          path={APP_ROUTES.SMARTVITALS_CHECKER}
          component={SmartVitalsChecker}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path={APP_ROUTES.SMARTVITALS_INSTRUCT}
          component={SmartVitalsInstruct}
        ></PrivateRoute>
         <Route
          exact
          path={APP_ROUTES.SMARTVITALS_REPORT}
          component={SmartVitalsReport}
        ></Route>
        <PrivateRoute
          exact
          path={APP_ROUTES.CARTREVIEW}
          component={CartReview}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path={APP_ROUTES.CART_ORDER_SUCESS}
          component={CartOrderSucess}
        ></PrivateRoute>

       
        <PrivateRoute
          exact
          path={APP_ROUTES.APPOINMENT_CONFIRM}
          component={AppoinmentConfirmation}
        ></PrivateRoute>
        <PrivateRoute
          path={APP_ROUTES.MEDICINE_ORDERPLACE}
          component={MedicineOrder}
        ></PrivateRoute>
         <PrivateRoute
          path={APP_ROUTES.MEMBERSHIP_DOC}
          component={MembershipDocument}
        ></PrivateRoute>
         <PrivateRoute exact path={APP_ROUTES.ASKCARESATHI} component={CareSathi} />
      </Switch>
    </Router>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkIsLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }
  />
);

export default PageRouting;
